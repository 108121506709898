import axios from "axios";

async function getAnswer(question) {
  const prompts = question.toString();
  const response = await axios.post(
    "https://gpt.vialiceq.workers.dev/",
    {
      prompts 
      
    },
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods" : "*",
          // "Access-Control-Allow-Credentials" : "true",
          "Access-Control-Allow-Headers" : "*",
          // "Access-Control-Max-Age" :"120"
         "Accept":"application/json, text/plain,*/*" ,
          "Content-Type": "text/plain",
        
      },
    }
  ).then((response) => {
    // console.log(response.data.choices[0].text.trim());
    console.log(response)
    return response.data
  }).catch((err) => {
    console.log(err)
    alert(err.message+"\n please make sure your internet connection  is in OpenAI supported country!")
    console.error(err.message)
  });
  // return console.log(response.data.choices[0].text.trim());

  return response;
 
}



export default getAnswer;
